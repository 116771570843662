h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}
.wrapper {
  background-color: #f2f2f2;
  min-height: 100vh;
  height: 100%;
}
.main-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.leftside-img {
  width: 50%;
}
.main-container {
  padding: 0 20px;
  width: 50%;
}
@media (max-width: 768px) {
  .main-div {
    display: block;
  }
  .leftside-img {
    display: none;
  }
  .main-container {
    width: 100%;
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

@media (max-width: 400px) {
  .header {
    flex-direction: column;
    align-items: start;
    gap: 0;
    margin-bottom: 10px;
  }
  .profile {
    text-align: left;
  }
}
.timeline-container {
  display: flex;
  align-items: center;
  /* padding: 10px 0 0 0; */
}
.password-timeline {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.circle {
  height: 40px;
  width: 40px;
  /* background-color: ;
   */
  border: 1px solid #24949e;
  border-radius: 50%;
  /* color: #fff; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
}
.line {
  height: 2px;
  background-color: #24949e;
  width: 100%;
}
.timeline-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.err {
  color: red;
  font-size: 12px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* LOADER */
.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #24949e;
  border-bottom: 8px solid #24949e;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
